/* eslint-disable @typescript-eslint/prefer-for-of */
import qs, { parseUrl } from 'query-string';
// @ts-ignore
import { create, all } from 'mathjs';
import _request, { requestBlob } from './request';


export const math: any = create(all, {
  epsilon: 1e-12,
  matrix: 'Matrix',
  number: 'BigNumber',
  precision: 64,
  // @ts-ignore
  predictable: false,
  randomSeed: undefined,
});

export const mathMultiply = (val1: any, val2: any) => math.chain(math.bignumber(val1)).multiply(math.bignumber(val2)).done().toString();

export const transformAmount: any = (amount: number | undefined) => {
  return (amount || 0) / 100;
};

interface IObjectAny {
  [propName: string]: any;
}

export type Result<T> = {
  success: true;
  data: T;
  response: any; // 原始数据
} | {
  success: false;
  err: Error;
  message: string;
  data?: any;
}

export const renderCardStatus = (status: string) => {
  if (status === 'INIT' || status === 'INIT_CREATE_CARD' || status === 'INIT_CREATE_CARD_HOLDER' || status === 'INIT_ACTIVE_CARD') return '开卡中';
  if (status === 'ACTIVE') return '已激活';
  if (status === 'FREEZE') return '已冻结';
  if (status === 'CREATE_CARD_FAILED_REFUND') return '开卡失败-退款';
  if (status === 'CANCELLING') return '删卡中';
  if (status === 'CANCELED' || status === 'DELETED') return '已删除';
  return '-';
};


export const getStartTime = (time: any) => {
  const date = new Date(time);
  return date.setHours(0, 0, 0, 0);
};

export const getEndTime = (time: any) => {
  const date = new Date(time);
  return date.setHours(23, 59, 59, 999);
};

export const getMonthStartTime = (time: any) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month, 1).getTime();
};
export const getMonthEndTime = (time: any) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month + 1, 1).getTime() - 1;
};



/**
 * 自动解包多级 data （当 data 存在且仅存在 data 属性）
 * TODO 可以对 request 包一层，避免样板代码
 */
export function normalizeResult<T = any>(
  res: { err: Error | null; data: any },
): Result<T> {
  if (!res) {
    return {
      success: false,
      data: null,
      message: '',
      err: new Error(''),
    };
  }
  if (res.err) {
    return {
      success: false,
      err: res.err,
      message: '',
    };
  } else {
    // 第一层 data 是服务端返回的原始 response
    let { data } = res;

    /**
     * data 是数组的情况
     * （还没有发现什么接口什么情况下服务端会直接返回数组，虽然双春说有）
     */
    if (Array.isArray(data)) {
      return {
        success: true,
        data: data as any,
        response: res.data,
      };
    }

    /**
     * 返回错误
     */
    if (data.success !== true) {
      return data;
    }

    // 第二层 data 为 response 的 data 属性
    ({ data } = data);

    // 如果 data 下面有且只有一层 data，则进入下一层
    while (
      data &&
      Object.prototype.hasOwnProperty.call(data, 'data') &&
      Object.keys(data).length === 1
    ) {
      ({ data } = data);
    }

    return {
      success: true,
      data,
      response: res.data,
    };
  }
}


// 若传入 format='format' 则走规范
export const dateFormat = (date: (string | number | Date), format = 'yyyy-MM-dd hh:mm:ss TT'): string => {
  if (!date) {
    date = new Date();
  }
  if (typeof date === 'string' && /^\d+$/.test(date)) {
    date = new Date(+date);
  }
  if (typeof date === 'number') {
    date = new Date(date);
  }
  if (typeof date !== 'number' && !(date instanceof Date)) {
    date = date.replace(/年|月/g, '-').replace(/日/g, '');
    date = new Date(date);
  }

  const duration = Date.now() - date.getTime();
  const level1 = 60 * 1000; // 1 分钟
  const level2 = 60 * 60 * 1000; // 1 小时
  const level3 = 24 * 60 * 60 * 1000; // 1 天
  const level4 = 2 * 24 * 60 * 60 * 1000; // 2天

  if (format === 'default') {
    if (duration < level1) {
      return '刚刚';
    }
    if (duration >= level1 && duration < level2) {
      return `${Math.round(duration / level1)}分钟前`;
    }
    if (duration >= level2 && duration < level3) {
      return `${Math.round(duration / level2)}小时前`;
    }
    if (duration >= level3 && duration < level4) {
      format = '昨天 hh:mm';
    }
    // 判断是否过年了
    const _date = new Date();
    const _year = _date.getFullYear();
    if ((new Date(`${_year}-01-01`)).getTime() <= date.getTime()) {
      format = 'MM月dd日 hh:mm';
    } else {
      format = 'yyyy年MM月dd日 hh:mm';
    }
  }

  const o: IObjectAny = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时 24进制
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
    'T+': date.getHours() < 12 ? 'AM' : 'PM',
    'H+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时 12进制
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, String(date.getFullYear()).substr(4 - RegExp.$1.length));
  }
  Object.keys(o).forEach(k => {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? (o[k]) : `00${o[k]}`.substr(String(o[k]).length),
      );
    }
  });
  return format;
};

export const getStringLength = (str: string) => {
  return str.replace(/[\u0391-\uFFE5]/gim, 'aa').length;
};

export const isMobile = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod'];
  let flag = false;

  for (const v of Agents) {
    if (userAgentInfo.includes(v)) {
      flag = true;
      break;
    }
  }
  return flag;
};

// 加载script
export function loadScript(src: string, ignore?: boolean) {
  // 检测是否重复
  if (!ignore) {
    const scripts = document.getElementsByTagName('script');
    let isOwned = false;
    for (let i = 0; i < scripts.length; i++) {
      const _src = scripts[i].getAttribute('src');
      isOwned = isOwned || _src === src;
    }
    if (isOwned) {
      return new Promise((resolve) => {
        resolve('');
      });
    }
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head && document.head.appendChild(script);
  });
}

// 解析query-string为key-value格式
export const getQuery = (param?: string) => {
  const url = param || window.location.search;
  return parseUrl(url).query;
};

// 休眠 延迟
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const transformDate = (number: number) => {
  return number >= 10 ? String(number) : `0${number}`;
};

export const getStriptDate = (year?: number, month?: number) => {
  if (!year || !month) return '';
  return `${transformDate(month)}/${String(year).slice(2)}`;
};

export const getTodayTime = () => {
  const date = new Date();
  return `${String(date.getFullYear()).slice(2)}/${transformDate(date.getMonth() + 1)}/${transformDate(date.getDate())}`;
};

// 生成guid
export const guid = () => {
  return (
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    '-' +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    '-' +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    '-' +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    '-' +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) +
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  );
};

export const object2string = (options: any) => {
  return qs.stringify(options);
};

// url 接口, data 入参, options 配置
export const request = {
  get: (url: string, data = {}, options?: any) => {
    const query = object2string(data);
    const sep = url.includes('?') ? '&' : '?';
    return _request(`${url}${query ? sep : ''}${query}`);
  },
  getBlob: (url: string, data = {}, options?: any) => {
    const query = object2string(data);
    const sep = url.includes('?') ? '&' : '?';
    return requestBlob(`${url}${query ? sep : ''}${query}`);
  },
  postBlob: (url: string, data = {}, options?: any) => {
    return requestBlob(url, {
      method: 'POST',
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  post: (url: string, data = {}, options?: any) => {
    return _request(url, {
      method: 'POST',
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  put: (url: string, data = {}, options?: any) => {
    return _request(url, {
      method: 'PUT',
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  delete: (url: string, data = {}, options?: any) => {
    const query = object2string(data);
    const sep = url.includes('?') ? '&' : '?';
    return _request(`${url}${query ? sep : ''}${query}`, {
      method: 'DELETE',
    });
  },
};

export const toFixed = (val: string | number = 0, persion = 0) => {
  return Number(val).toFixed(persion);
};

export const isWindows = () => window.navigator.appVersion.includes('Win');

export const initStyle = () => {

};

export const flat = (nodes: any[]): any[] => {
  let result: any[] = [];
  if (!nodes || nodes.length === 0) return [];
  nodes.forEach(node => {
    const newNode = { ...node };
    const { children } = newNode;
    delete newNode.children;
    result.push(newNode);
    const _result = flat(children);
    result = [...result, ..._result];
  });
  return result;
};

export const downloadBlobUrl = (blobUrl: any, name: string) => {
  let a: any = document.createElement('a');
  a.style.display = 'none';
  a.download = name;
  a.href = blobUrl;
  a.click();
  a = null;
};

export const formatFloat = (src: any, pos: any) => {
  // eslint-disable-next-line no-restricted-properties
  return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
};

export const changeTheme = (theme: string) => {
  const root = document.documentElement;
  root.className = theme;
};
