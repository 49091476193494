import { request } from 'utils/tool';
import { PREFIX } from 'constants/host';
import Notify from 'ui-component/Tools/Notify';
import { store } from 'store';
import * as ActionTypes from 'store/actions';
import { getResult } from './common';

export const registerUser = async (options: { smsCode: string; code: string; password: string; mobile: string; name: string }) => {
  const res = getResult<boolean>(await request.post(`${PREFIX}/user/register`, options));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const loginByPassword = async (options: { mobile: string; password: string }) => {
  const res = getResult<{
    token: string;
    authType: string;
  }>(await request.post(`${PREFIX}/user/login`, options));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const userLogout = async () => {
  const res = getResult<boolean>(await request.post(`${PREFIX}/user-center/logout`));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export type UserDetail = {
  overview: {
    showUsdtTopupCostPreview: boolean;
    usdtTron?: string;
    balance: number;
    consume: number;
    deposit: number;

    declineNum: number; // 拒付数
    declineRatio: number; // 拒付率
    pendingNum: number; // pending数
    vccNum: number; // 开卡数
    vccTotalNum: number; // 总开卡数
    refundNum: number; // 退款数
    refundRatio: number; // 退款率
    completeRatio: number; // 支付成功率

    totalDeposit: number; // 总充值
    vccOnlineNum: number; // 激活卡片数
    vccDeletedNum: number; // 累计删卡
    availableAccountCurrency: string[];
    futureTron?: {
      address?: string;
      enable: boolean;
    };
    coinTron?: {
      address?: string;
      enable: boolean;
    };
  };
  account: {
    createTime: number;
    mobile: string;
    name: string;
    userId: string;
    features: string[];
    agent: {
      showMenu: boolean;
    };
  };
  featureConfig: {
    canUseYsDbBalance: boolean;
    ysCardOnlyPayByCny: boolean;
    enableAccountTransfer: boolean;
  };
  userStatus?: {
    googleAuthStatus: string;
  };
};

export const getUserDetail = async () => {
  const res = getResult<UserDetail>(await request.get(`${PREFIX}/user/overview`));
  if (!res.success) {
    Notify('error', res.msg);
  } else {
    store.dispatch({
      type: ActionTypes.SET_USER_INFO,
      payload: {
        user: res.data
      }
    });
  }
  return res;
};

export type Broadcast = {
  read: boolean;
  createTime: string;
  updateTime: string;
  id: number;
  msg: string;
  title: string;
  popUp: boolean;
  popEndTime?: number;
  popStartTime?: number;
};

type BroadcastResult = {
  data: Broadcast[];
  current: number;
  size: number;
  total: number;
};

export const getUnReadBroadcast = async (options: { current: number; size?: number }) => {
  const res = await getResult<BroadcastResult>(await request.get(`${PREFIX}/broadcast/list`, options));
  if (!res.success) {
    Notify('error', res.msg);
  }

  return res;
};

export type Rebate = {
  cutRate: number;
  desp: string;
  rebate: number;
  rebateId: string;
  sourceTypeText: string;
  source: string;
  userId: string;
};

type RebateResult = {
  data: Rebate[];
  current: number;
  size: number;
  total: number;
};

export const getRebateList = async (options: { current: number; size?: number }) => {
  const res = await getResult<RebateResult>(await request.get(`${PREFIX}/agent/rebate/list`, options));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export type Customer = {
  mobile: string;
  nickName: string;
  rebate: number;
  userId: string;
};

type CustomerResult = {
  data: Customer[];
  pageNo: number;
  pageSize: number;
  total: number;
};

export const getCustomerList = async (options: { current: number; size?: number }) => {
  const res = await getResult<CustomerResult>(await request.get(`${PREFIX}/agent/customer/list`, options));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export type AgentInfo = {
  agentId: string;
  inviteCode: string;
  visitCount: number;
  invitedCount: number;
  withDrawRebate: number;
  remainRebate: number;
  minWithdrawAmount: number;
  refundCutRate: number | string;
  blockRebate: number;
  cardTopupCutRate: number | string;
  totalRebate: number | string;
  newCardCutRate: string;
  privilegeCutRate: number;
  dataShowable: boolean;
  rebateEnable: boolean;
  showable: boolean;
  disabled: boolean;
};

export const getAgentInfo = async () => {
  const res = await getResult<AgentInfo>(await request.get(`${PREFIX}/agent/detail`));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const getLatestInviteCode = async () => {
  const res = await getResult<{
    inviteCode: string;
  }>(await request.get(`${PREFIX}/agent/invite-code/show-one`));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const refreshInviteCode = async () => {
  const res = await getResult<{
    inviteCode: string;
  }>(await request.post(`${PREFIX}/agent/invite-code/refresh`));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const getGoogleAuth = async () => {
  const res = await getResult<{
    qrImg: string;
  }>(await request.get(`${PREFIX}/auth/google/pair/qr/img`));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const bindGoogleAuth = async (option: { pin: string | number }) => {
  const res = await getResult<boolean>(await request.post(`${PREFIX}/auth/google/pair`, option));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const unBindGoogleAuth = async (option: { pin: string | number }) => {
  const res = await getResult<boolean>(await request.post(`${PREFIX}/auth/google/delete`, option));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const loginWithGoogleAuth = async (option: { pin: string | number; mobile: string }) => {
  const res = await getResult<{
    token: string;
    authType: string;
  }>(await request.post(`${PREFIX}/user/googleAuth/login`, option));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const resetPassword = async (option: { pin: string | number; mobile: string; password: string }) => {
  const res = await getResult<boolean>(await request.post(`${PREFIX}/user/reset-password`, option));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};

export const transferAccount = async (option: {
  pin: string | number;
  mobile: string;
  amount: any;
  currency: string;
  verifyMethod: string;
}) => {
  const res = await getResult<boolean>(await request.post(`${PREFIX}/user-account/transfer`, option));
  if (!res.success) {
    Notify('error', res.msg);
  }
  return res;
};
