import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import notifyReducer from './notifyReducer';
import cardReducer from './cardReducer';
import userReducer from './userReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    notify: notifyReducer,
    card: cardReducer,
    user: userReducer
});

export default reducer;
