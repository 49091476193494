import { ReactNode } from 'react';
import { store } from 'store';
import { SET_NOTIFY_VISIBLE } from 'store/actions';
declare type NoticeType = 'success' | 'error' | 'info' | 'warning';
/**
 * MTools.Tips({ type, title })
 */

const Notify = (type: NoticeType, message: string | ReactNode) => {
  if (!message) return;
  store.dispatch({
    type: SET_NOTIFY_VISIBLE,
    message: message,
    status: type,
    visible: true
  })
};

export default Notify;
