import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CMP = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      id="切换语言"
      d="M79.1,87.648a11.965,11.965,0,0,1-12-11.93,11.761,11.761,0,0,1,.336-2.757.969.969,0,0,1,1.156-.7A.945.945,0,0,1,69.3,73.4a10,10,0,0,0,7.459,12.072,10.236,10.236,0,0,0,2.344.28.946.946,0,1,1,0,1.893ZM89.8,79.42a.952.952,0,0,1-.958-.946.942.942,0,0,1,.029-.232A10,10,0,0,0,81.553,66.08a10.236,10.236,0,0,0-2.455-.308.946.946,0,1,1,0-1.891h0a11.966,11.966,0,0,1,12,11.932,11.756,11.756,0,0,1-.371,2.9A.955.955,0,0,1,89.8,79.42Z"
      transform="translate(-67.098 -63.88)"
      fill="#213157"
    />
    <path
      id="切换语言-2"
      data-name="切换语言"
      d="M88.015,85.311a.828.828,0,0,1-.933-.875V81.172c0-.9-.6-.9-.82-.9a1.03,1.03,0,0,0-1.057,1c0,.012,0,.024,0,.036v3.122a.92.92,0,0,1-1.838,0V79.5a.833.833,0,0,1,.778-.887,1.131,1.131,0,0,1,.978.473,2.421,2.421,0,0,1,1.477-.471A2.164,2.164,0,0,1,88.921,81v3.435a.85.85,0,0,1-.238.629.92.92,0,0,1-.667.248Zm-6.26-.2H78.138c-1.149,0-1.661-.5-1.661-1.634V78.446c0-1.128.513-1.634,1.661-1.634h3.457a.861.861,0,1,1,0,1.722H78.474a.244.244,0,0,0-.1.013s0,.021,0,.077v1.4H81.22a.8.8,0,0,1,.875.705.772.772,0,0,1,0,.134.806.806,0,0,1-.763.848.816.816,0,0,1-.114,0H78.371V83.3a.282.282,0,0,0,.009.084.739.739,0,0,0,.091.006h3.284a.817.817,0,0,1,.877.856.807.807,0,0,1-.745.866.843.843,0,0,1-.132,0h0Z"
      transform="translate(-74.098 -74.831)"
      fill="#213157"
    />
    <path
      id="切换语言-3"
      data-name="切换语言"
      d="M74.092,75a.85.85,0,0,1-.959-.721.829.829,0,0,1-.005-.177V72.02h-2.29c-1.231,0-1.781-.545-1.781-1.76V67.77c0-1.21.551-1.749,1.781-1.749h2.29v-.837a.828.828,0,0,1,.765-.889.86.86,0,0,1,.155,0c.813,0,.984.482.984.887v.838h2.312c1.231,0,1.781.54,1.781,1.748v2.491c0,1.217-.551,1.759-1.781,1.759H75.032v2.084a.845.845,0,0,1-.789.9.874.874,0,0,1-.151,0h0ZM71.129,67.8c-.174,0-.19.016-.19.188v2.075c0,.173.016.19.19.19h2V67.8Zm3.9,2.451h2.022c.169,0,.19-.021.19-.188V67.982c0-.167-.021-.188-.19-.188H75.031v2.452h0Z"
      transform="translate(-57.057 -51.01)"
      fill="#213157"
    />
  </SvgIcon>
);

export default CMP;
