// assets
import { IconCreditCard, IconBulb, IconCalendarTime } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'cards',
    title: '我的卡片',
    type: 'group',
    children: [
        {
            id: 'createCard',
            title: '申请开卡',
            type: 'item',
            url: '/cards/create',
            icon: IconBulb,
            breadcrumbs: false
        },
        {
            id: 'cardList',
            title: '卡片列表',
            type: 'item',
            url: '/cards/list',
            icon: IconCreditCard,
            breadcrumbs: false
        },
        {
            id: 'transaction',
            title: '交易明细',
            type: 'item',
            url: '/cards/transaction',
            icon: IconCalendarTime,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
