import * as actionTypes from './actions';

export interface NotifyState {
  visible: boolean;
  message: string;
  status?: 'success' | 'info' | 'warning' | 'error';
}

export const initialState: NotifyState = {
  visible: false,
  message: '',
  status: 'info'
};

export interface ActionType extends NotifyState {
  type: string;
}

const notifyReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFY_VISIBLE:
      return {
        visible: action.visible,
        message: action.message,
        status: action.status || 'info'
      };
    default:
      return state;
  }
};

export default notifyReducer;
