import { useSelector, useDispatch } from 'react-redux';
import Websocket from 'ui-component/websocket';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalState } from 'store/types';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Box } from '@mui/material';
import { getUserDetail } from 'controller/auth';
import * as actionTypes from 'store/actions';
import { LANGUAGE } from 'constants/common';
import Storage from 'utils/storage';
import './i18n/config';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
// 系统右上角通知
import Notify from 'ui-component/Notify';

// ==============================|| APP ||============================== //

const App = () => {
  const { i18n } = useTranslation();
  const customization = useSelector((state: GlobalState) => state.customization);
  const loading = useSelector((state: GlobalState) => state.user.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_USER_INFO,
      payload: {
        loading: true
      }
    });
    Promise.all([getUserDetail()]).then(() => {
      dispatch({
        type: actionTypes.SET_USER_INFO,
        payload: {
          loading: false
        }
      });
    });
  }, [dispatch]);

  useEffect(() => {
    const LANGUAGES = ['en', 'zh'];
    const navigatorLan = navigator.language.split('-')[0];
    const defaultLan = (LANGUAGES.includes(navigatorLan) && navigatorLan) || 'en';
    const currentLan = Storage.get(LANGUAGE, defaultLan);
    i18n.changeLanguage(currentLan);
  }, [i18n]);

  if (loading) return <Box sx={{ position: 'fixed', top: '10px', left: 0, right: 0, bottom: 0, backgroundColor: '#fff' }} />;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
        <Notify />
        <Websocket />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
