// 通用参数
export interface FetchResultSuccess<T> {
  success: true;
  data: T | null;
  msg: string;
}

export interface FetchResultError {
  success: false;
  code: string | number;
  msg: string;
  data?: any;
}

export interface FetchPropsFunc<T> {
  (options: any): FetchResultSuccess<T> | FetchResultError;
}

// 梳理结果
export const getResult: <T>(data: any) => FetchResultSuccess<T> | FetchResultError = (result) => {
  if (result && result.success) {
    return {
      success: true,
      data: result && result.result || null,
      msg: result && result.msg || result.message || '',
    };
  } else {
    return {
      success: false,
      code: result && result.code || '-1',
      msg: result && (result.msg || result.message || ''),
    };
  }
};

// todo 后期做errorCode
export const resultError: any = (errorMsg: string) => {
  return {
    success: false,
    code: '-2',
    msg: errorMsg,
  };
};
