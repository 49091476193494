import { IS_TEST } from 'constants/host';
import { frameParse } from './parse.js';

let lockReconnect = false; // 避免重复连接
const isHttps = location.protocol.startsWith('https');
const socketProtocol = isHttps ? 'wss' : 'wss';
const wsUrl = IS_TEST ? `${socketProtocol}://test-api.cardseller.co/websocket` : `${socketProtocol}://api.coocard.tw//websocket`; // websocket链接
// const wsUrl = `${socketProtocol}://k8s-test.nobepay.com/websocket`;

let ws;

let userId;

const getWsl = () => {
  return `${wsUrl}/${userId}`;
};

/**
 * 发送消息
 * @param {string} msg 消息
 */
export function sendMessage(msg) {
  if (ws && ws.readyState === 1) {
    try {
      // Error: InvalidStateError: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.
      ws.send((typeof msg === 'object' && JSON.stringify(msg)) || msg);
    } catch (e) {
      // console.log('e:', e);
    }
  }
}

// 心跳检测
const heartCheck = {
  timeout: 50000,
  timeoutObj: null,
  serverTimeoutObj: null,
  start: function () {
    // eslint-disable-line
    const self = this;
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    this.timeoutObj = setTimeout(function () {
      // eslint-disable-line
      // 发送测试信息，后端收到后，返回一个消息，
      // const swToken = Cookie.get('_sw_token');
      sendMessage({
        type: 'ping'
      });
      // ws.send('ping');
      // console.log('ping');
      self.serverTimeoutObj = setTimeout(function () {
        // eslint-disable-line
        ws.close();
      }, self.timeout);
    }, this.timeout);
  }
};

function onOpen() {
  // console.log('start...', evt);
  // const swToken = Cookie.get('_sw_token');
  // 查询所有
  // sendMessage('full');
  // ws.send('full');
  // 心跳检测重置
  heartCheck.start();
}

function onClose() {
  // evt
  // console.log('close:', evt);
}

function onError() {
  // evt
  // console.log('通信错误：' + evt.data);
}

function onMessage(evt) {
  try {
    const data = JSON.parse(evt.data);
    if (data.type) {
      // 心跳数据不处理
      // 所需的正常操作
      if (data.type === 'pong') {
        // console.log('pong:', evt.data);
      } else {
        // console.log(new Date());
        // console.log('接收消息: ' + evt.data, '---');
        frameParse(data);
      }
    } else {
      console.log('收到非格式化数据');
    }
  } catch (e) {
    console.log(e);
  }

  // 拿到任何消息都说明当前连接是正常的
  heartCheck.start();
}

function websocketReconnect(url) {
  if (lockReconnect) {
    // 是否已经执行重连
    return;
  }
  lockReconnect = true;
  // 没连接上会一直重连，设置延迟避免请求过多
  tt && clearTimeout(tt); // eslint-disable-line
  var tt = setTimeout(function () {
    // eslint-disable-line
    createWebSocket(url); // eslint-disable-line
    lockReconnect = false;
  }, 5000);
}

function websocketInit() {
  // 建立 web socket 连接成功触发事件
  ws.onopen = function (evt) {
    // eslint-disable-line
    onOpen(evt);
  };
  // 断开 web socket 连接成功触发事件
  ws.onclose = function (evt) {
    // eslint-disable-line
    console.log('onclose', evt);
    websocketReconnect();
    onClose(evt);
  };
  // 接收服务端数据时触发事件
  ws.onmessage = function (evt) {
    // eslint-disable-line
    onMessage(evt);
  };
  // 通信发生错误时触发
  ws.onerror = function (evt) {
    // eslint-disable-line
    console.log('errr', evt);
    websocketReconnect();
    onError(evt);
  };
}

export function createWebSocket(id) {
  if (id) {
    userId = id;
  }
  try {
    ws = new WebSocket(getWsl());
    websocketInit();
  } catch (e) {
    console.log('catch');
    websocketReconnect();
  }
}

/**
 * 关闭socket的方式
 */
export function closeSocket() {
  if (ws) {
    ws.onclose = function () {}; //eslint-disable-line
    ws.close();
  }
}
