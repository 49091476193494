import * as actionTypes from './actions';
import { UserDetail } from 'controller/auth';

export interface UserState {
  user?: Partial<UserDetail>;
  loading: boolean;
}

export const initialState: UserState = {
  user: {
  },
  loading: false,
};

export interface ActionType {
  type: string;
  payload: Partial<UserState>;
}

const userReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.INIT_USER_INFO:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default userReducer;
