// material-ui
import { Typography } from '@mui/material';
import { useSelector, shallowEqual } from 'react-redux';
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const user = useSelector(({ user }) => user.user, shallowEqual);
    const powerHidden = {
        agent: !user.account?.agent?.showMenu,
        isSubUser: user.account?.isGroupSubUser,
        enable: user?.overview?.groupConfig?.enable,
        showUsdtTopupCostPreview: user?.overview?.showUsdtTopupCostPreview,
    };
    const navItems = menuItem.items.map((item) => {
        if (item.children) {
            item.children.map(x => {
                if (x._hidden) {
                    x.hidden = x._hidden(powerHidden)
                }
                return x;
            })
        }
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    return <>{navItems}</>;
};

export default MenuList;
