import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { IS_TEST } from 'constants/host';
import common from './zh/common.json';
import menu from './zh/menu.json';

import menuEn from './en/menu.json';
import commonEn from './en/common.json';

export const defaultNS = 'zh';

export const resources = {
  zh: {
    common,
    menu
  },
  en: {
    menu: menuEn,
    common: commonEn
  }
};

i18next.use(initReactI18next).init({
  lng: 'zh', // if you're using a language detector, do not define the lng option
  fallbackLng: 'zh',

  interpolation: {
    escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  debug: IS_TEST,
  resources,
  defaultNS
});
