// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - notify
export const SET_NOTIFY_VISIBLE = '@notify/SET_NOTIFY_VISIBLE';

// action - card reducer
export const CARD_INIT = '@card/INIT_STATE';
export const CARD_UPDATE = '@card/CARD_UPDATE';

// user reducer
export const SET_USER_INFO = '@user/SET_USER_INFO';
export const INIT_USER_INFO = '@user/INIT_USER_INFO';