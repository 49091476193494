import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { GlobalState } from 'store/types';
import { createWebSocket } from 'utils/task/socket';

const Websocket = () => {
  const userId = useSelector(({ user }: GlobalState) => user.user?.account?.userId, shallowEqual);
  useEffect(() => {
    if (userId) {
      createWebSocket(userId);
    }
  }, [userId]);
  return null;
};

export default Websocket;
