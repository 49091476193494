// project imports
import config from 'config';
import { UserCardItem, CardSegment } from 'controller/card';

// action - state management
import * as actionTypes from './actions';

export interface CardState {
  cardList: UserCardItem[];
  current: number;
  size: number;
  refresh: number;
  total: number;
  selectedRowKeys: string[];
  loading: boolean;
  initLoading: boolean;
  filter: any;
  cardSegmentList: CardSegment[];
}

export const initialState: CardState = {
  cardList: [],
  current: 1,
  size: 10,
  total: 0,
  selectedRowKeys: [],
  loading: true,
  initLoading: true,
  refresh: 0,
  filter: {},
  cardSegmentList: [],
};

export interface ActionType {
  type: string;
  payload: CardState;
}

const notifyReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.CARD_INIT:
      return {
        ...initialState,
      };
    case actionTypes.CARD_UPDATE:
      return {
        ...state,
        ...(action.payload || {})
      };
    default:
      return state;
  }
};

export default notifyReducer;
