import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { GlobalState } from 'store/types';
import { ActionType } from 'store/notifyReducer';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SET_NOTIFY_VISIBLE } from 'store/actions';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} sx={{ minWidth: 300 }} ref={ref} variant="filled" {...props} />;
});

const CMP = () => {
  const notify = useSelector((state: GlobalState) => state.notify);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch<ActionType>({
      type: SET_NOTIFY_VISIBLE,
      visible: false,
      message: ''
    });
  };

  return (
    <Snackbar
      open={notify.visible}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={notify.status}>{notify.message}</Alert>
    </Snackbar>
  );
};

export default CMP;
