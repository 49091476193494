import { lazy } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CreateCard = Loadable(lazy(() => import('views/cards/create')));
const CardList = Loadable(lazy(() => import('views/cards/cardList')));
const Trade = Loadable(lazy(() => import('views/cards/transaction')));
const Transaction = Loadable(lazy(() => import('views/finance/transaction')));
const Agent = Loadable(lazy(() => import('views/agent')));
const Setting = Loadable(lazy(() => import('views/setting')));
const Group = Loadable(lazy(() => import('views/group')));
const USDT = Loadable(lazy(() => import('views/usdt')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  const agent = useSelector(({ user }) => user.user?.account?.agent, shallowEqual);
  const isSubUser = useSelector(({ user }) => user.user?.account?.isGroupSubUser, shallowEqual);
  const enable = useSelector(({ user }) => user.user?.overview?.groupConfig?.enable, shallowEqual);
  const showUsdtTopupCostPreview = useSelector(({ user }) => user.user?.overview?.showUsdtTopupCostPreview, shallowEqual);
  return {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <DashboardDefault />
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: <DashboardDefault />
          }
        ]
      },
      {
        path: '/cards',
        children: [
          {
            path: 'create',
            element: <CreateCard />
          },
          {
            path: 'list',
            element: <CardList />
          },
          {
            path: 'transaction',
            element: <Trade />
          }
        ]
      },
      {
        path: '/account',
        children: [
          ...(!isSubUser && showUsdtTopupCostPreview
            ? [
                {
                  path: 'usd',
                  element: <USDT />
                }
              ]
            : []),
          {
            path: 'transaction',
            element: <Transaction />
          },
          ...(!isSubUser && enable
            ? [
                {
                  path: 'group',
                  element: <Group />
                }
              ]
            : []),
          {
            path: 'setting',
            element: <Setting />
          },
          {
            hidden: !agent?.showMenu,
            path: 'invite',
            element: <Agent />
          }
        ]
      }
    ]
  };
};

export default MainRoutes;
