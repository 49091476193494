import { useRoutes } from 'react-router-dom';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const transFormRoute = (routes) => {
    if (routes.children) {
        routes.children = routes.children.filter(x => {
            if (x.hidden) return false;
            if (x.children) {
                transFormRoute(x);
            }
            return true;
        })
    }
    return routes;
}

export default function ThemeRoutes() {
    const manRoutes = transFormRoute(MainRoutes());

    return useRoutes([manRoutes, AuthenticationRoutes]);
}
