import dashboard from './dashboard';
import cards from './cards';
import account from './account';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, cards, account]
};

export default menuItems;
