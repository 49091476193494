import Notify from 'ui-component/Tools/Notify';

/**
 * 数据解析
 * @param {Object} data 返回对象
 */
export const frameParse = async (data) => {
  console.log(data);
  if (data.success) {
    // 支付成功
    if (data.type === 'paySuccess') {
      Notify('success', '支付成功');
      store.dispatch({
        type: 'socket/update',
        payload: {
          payStatus: true
        }
      });
      store.dispatch({
        type: 'auth/getUserSummary',
        payload: {}
      });
      authController.getUserDetail();
    }
  } else {
    if (data.type === 'payExpired') {
      return;
    }
    Notify('error', data.msg);
  }
};
