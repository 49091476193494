// assets
import { IconBoxPadding, IconGhost, IconSettings, IconBrowserPlus, IconRecharging } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'account',
  title: '账户管理',
  type: 'group',
  children: [
    {
      id: 'topup',
      title: '在线充值',
      type: 'item',
      url: '/account/usd',
      icon: IconRecharging,
      breadcrumbs: false,
      _hidden: (power) => power?.isSubUser || !power.showUsdtTopupCostPreview
    },
    {
      id: 'accountTransaction',
      title: '资金活动',
      type: 'item',
      url: '/account/transaction',
      icon: IconBoxPadding,
      breadcrumbs: false
    },
    {
      id: 'subAccount',
      title: '子账户',
      type: 'item',
      url: '/account/group',
      icon: IconBrowserPlus,
      breadcrumbs: false,
      _hidden: (power) => power?.isSubUser || !power.enable
    },
    {
      id: 'agentCenter',
      title: '代理中心',
      type: 'item',
      url: '/account/invite',
      icon: IconGhost,
      breadcrumbs: false,
      _hidden: (power) => power?.agent
    },
    {
      id: 'setting',
      title: '设置',
      type: 'item',
      url: '/account/setting',
      icon: IconSettings,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
