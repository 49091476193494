// 是否是测试模式
export const IS_TEST = location.hostname.includes('www123.cardselle');

// 账号中心 host
export const ACCOUNT_HOST = '';

//  MAGIC hosts
export const PREFIX = IS_TEST ? (
  // `${window.location.protocol}//app.test.capte.us/api/v1`
  'https://test-api.cardseller.co/pm-api-v3'
) : 'https://api.feetcard.com/pm-api-v3';
